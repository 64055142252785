import {T} from '@deity-io/falcon-i18n';
import loadable from '@loadable/component';
import clsx from 'clsx';
import React, {memo, useEffect, useState} from 'react';
import {useQuery} from '@apollo/react-hooks';
import ProductHeader from '../../components/Product/ProductHeader/ProductHeader';
import {ProductProvider} from '../../context/ProductContext';
import useResponsive from '../../hooks/useResponsive';
import {GET_BACKEND_CONFIG} from '../../commonQueries';
import {useSamitoReset} from '../../hooks/useSamito';
import StructuralDataProduct from '../../components/StructuralData/StructuralDataProduct';
import './Product.scss';
import SeoBlock from "../../components/SeoBlock";
import {useQuarticonContext} from "../../context/QuarticonContext";
import {sendProductsQuarticon} from "../../hooks/useQuarticonHooks";
import {productDetailEvent} from '../../hooks/useAnalytics';
const QuarticonRecommendations = loadable(() => import('../../components/Quarticon/QuarticonRecommendations'), {
    ssr: false,
});
const ProductGallery = loadable(() => import('../../components/Product/ProductGallery/ProductGallery'));
const Breadcrumbs = loadable(() => import('../../components/Category/Breadcrumbs/Breadcrumbs'));
const ProductSidebar = loadable(() => import('../../components/Product/ProductSidebar/ProductSidebar'));
const ProductTabs = loadable(() => import('../../components/Product/ProductTabs/ProductTabs'));
const Helmet = loadable(() => import('react-helmet'));

const ProductShareButtons = loadable(() => import('../../components/Product/ProductShareButtons/ProductShareButtons'), {
    ssr: false,
});
const Products = loadable(() => import('../../components/Category/Products/Products'), {ssr: false});
const FlixIntegration = loadable(() => import('./FlixIntegration'), {ssr: false});
const LoadbeeBoschIntegration = loadable(() => import('./LoadbeeBoschIntegration'), {ssr: false});
const AdriaticIntegration = loadable(() => import('./AdriaticIntegration'), {ssr: false});

// TODO: Fetch these as configs when it becomes available - current values from emmezeta feature

const ProductPage = ({product}) => {
    const {isMobile} = useResponsive();
    const {data: configProvider} = useQuery(GET_BACKEND_CONFIG);

    const {
        sku,
        name,
        categories,
        gallery,
        related_products,
        upsell_products,
        lifetime_status,
        is_on_order_only,
        brand,
        barcode,
        prices,
        days_to_delivery,
    } = product || {};

    const breadcrumbs = categories && categories[categories.length - 1];
    // Strip HTML from product description

    const [showMore, setShowMore] = useState(false);
    const [hasFeed, setHasFeed] = useState(false);
    const hideQuarticon = !product.hide_quarticon ? false : product.hide_quarticon;
    useSamitoReset();

    const { tracker } = useQuarticonContext();
    useEffect(()=>{
        sendProductsQuarticon(sku, tracker)
        productDetailEvent(product)
    },[sku])


    return (
        <ProductProvider value={product}>
            <SeoBlock routeData={product}/>
            <main className="product">
                <Helmet>
                    {brand === 'BOSCH' && <script defer async src="https://cdn.loadbee.com/js/loadbee_integration.js"/>}
                </Helmet>
                <StructuralDataProduct
                    product={product}
                />
                {!isMobile && <ProductHeader product={product}/>}
                <article className="product__main">
                    <section className="product__main__left">
                        <Breadcrumbs routeData={breadcrumbs} name={name}/>
                        <h1 className="product__name">{name}</h1>
                        <div className="product__social-buttons">
                            <ProductShareButtons/>
                        </div>
                        {gallery && (
                            <ProductGallery
                                product={product}
                                data={gallery}
                                lifetime_status={lifetime_status}
                                is_on_order_only={is_on_order_only}
                                days_to_delivery={days_to_delivery}
                            />
                        )}
                        {isMobile && <ProductSidebar product={product} setTab={() => {
                        }}/>}
                        <ProductTabs product={product}/>
                    </section>
                    <section className="product__main__right">
                        {!isMobile && <ProductSidebar product={product} setTab={() => {
                        }}/>}
                    </section>
                    <div style={{position:"relative",width:'100%'}}>
                        <div
                            className={clsx({
                                'product__syndication-main-div': true,
                                'product__syndication-main-div__opened': showMore,
                            })}
                        >
                            <FlixIntegration
                                brand={brand}
                                barcode={barcode}
                                prices={prices}
                                setHasFeed={setHasFeed}
                                hasFeed={hasFeed}
                                data={configProvider}
                            />
                            {brand && brand.toUpperCase()=='BOSCH' && <LoadbeeBoschIntegration brand={brand} barcode={barcode} setHasFeed={setHasFeed}/>}
                            <AdriaticIntegration brand={brand} sku={sku} setHasFeed={setHasFeed}/>
                        </div>

                    </div>

                    {!hideQuarticon && <QuarticonRecommendations sku={sku} type="product_page"/>}

                    {!!related_products.length && (
                        <div className="product__related">
                            <h2>
                                <T id="product.similarProducts"/>
                            </h2>
                            <Products items={related_products}/>
                        </div>
                    )}
                    {!!upsell_products.length && (
                        <div className="product__related">
                            <h2>
                                <T id="product.mightInterestYou"/>
                            </h2>
                            <Products items={upsell_products}/>
                        </div>
                    )}
                    {/* isMobile && <ProductTabs product={product} /> */}
                </article>
            </main>
        </ProductProvider>
    );
};

export default memo(ProductPage);
